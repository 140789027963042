    .reasons{
    display: flex;
    position: relative;
}

.left{
    display: grid;
    flex: 1 1;
    gap: 16px;
    grid-auto-rows: 1fr;
}

.right{
    flex: 1 1;
    margin-left: 50px;
}

.left>img{
    object-fit: fit;
}

.left>:nth-child(1){
    
    grid-row: 1/3;
    height: 29.3rem;
    margin-left: 30px;
    
}

.left>:nth-child(2){
    height: 17rem;
    grid-column: 2/4;

}

.left>:nth-child(3){
    width: 14rem;    
    grid-column: 2/3;
    grid-row: 2;

}

.left>:nth-child(4){
    width: 11rem;    
    grid-column: 3/4;
    grid-row: 2;
    height: 11.2rem;
}

.right>div{
    font-size: 48px;
    font-weight: bold;
    color: white;
}

.details{
    display: flex;
    gap: 25px;
    flex-direction: column;
    margin-top: 25px;
    margin-bottom: 20px;
}

.details>div>span{
    font-size: 16px;
}

.details>div>img{
    height: 30px;
}

.details>div{
    display: flex;
    gap: 16px;
}

.partners{
    display: flex;
    gap: 10px;
    height: 30px;
    margin: 15px 0;
}