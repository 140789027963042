.header{
    display: flex;
    justify-content: space-between;
}

.logo{
    width: 120px;
    height: 40px;
}

.header-menu{
    list-style: none;
    display: flex;
    gap: 20px;
    color: white;
    cursor: pointer;
}

.header-menu a{
    color: white;
    text-decoration: none;
}

.header-menu>li:hover{
    color: #f48915;
}