.testimonials{
    display: flex;
    padding: 0 32px;
    gap: 32px;
    
}

.right-t{
    flex: 1 1;
    position: relative;
}

.left-t{
    display: flex;
    flex: 1 1;
    flex-direction: column;
    gap: 32px;
    color: white;
    
}

.left-t>:nth-child(1), 
.left-t>:nth-child(2)
{
    font-size: 60px;
    font-weight: bold;
}


.left-t>:nth-child(3){
    letter-spacing: 1.5px;
    line-height: 40px;
    text-align: justify;

}



.right-t>img{
    position: absolute;
    width: 17rem;
    height: 20rem;
    object-fit: cover;
    right: 8rem;
    top: 2rem;
}

.right-t>:nth-child(1){
    position: absolute;
    width: 17rem; height: 20rem;
    border: 2px solid orange;
    background-color: transparent;
    top: 0.9rem;
    right: 9rem;
}

.right-t>:nth-child(2){
    position: absolute;
    width: 17rem;
    height: 19rem;
    right: 7rem;
    top: 4rem;
    background:linear-gradient(210.41deg, #fa5042 1.14%, #ffa739 100.75%);;
}

.arrows{
    display: flex;
    position: absolute;
    gap: 16px;
    bottom: 16px;
    left: 48px;
}

.arrows>img{
    width: 24px;
    cursor: pointer;
}
